<template>
  <v-container> </v-container>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("logout");
    this.$store.dispatch("updateRolePermission");
    // this.$store.dispatch("updateTeam");
    this.$router.push({ name: "PageGuestHome" });
  },
};
</script>
